<template>
  <div id="customerListPagee" class="p-3">
    <!-- <el-checkbox v-model="onlyRegisteredCustomers" style="margin-left: 50px" @change="registeredCustomerCheckboxChange">Only Registered Customers</el-checkbox> -->

    <!-- Header -->
    <div class="sm:flex-auto">
      <div class="text-2xl font-semibold text-gray-900">Customers</div>
      <p class="mt-1 text-sm text-gray-700">List of all customer profiles synced with Growlytics.</p>
    </div>

    <!-- Customer List -->
    <div v-if="!fetchingData" class="overflow-hidden mt-2 w-full border border-gray-100 sm:rounded-lg mb-4">
      <!-- Header -->
      <div class="bg-white w-full border-b h-12 pl-3 pr-3">
        <div class="text-md pt-2.5 float-left">{{ pageOptions.total }} Customers</div>
        <div style="float: right" class="pt-2">
          &nbsp;
          <el-button type="primary" size="small" @click="onSearch">Search</el-button>
        </div>
        <div style="float: right" class="pt-2">
          <el-input style="width: 300px" prefix-icon="el-icon-search" size="small" v-model="searchTerm" placeholder="Search By Email or Mobile or System Id"> </el-input>
        </div>
      </div>

      <!-- Customer List -->
      <el-row class="customerItem" v-for="(customer, index) in customerList" :customer="customer" :key="index">
        <!-- Name if available -->
        <el-col :span="8">
          <div>
            <span v-if="customer.name && customer.name.trim()">{{ customer.name }}</span>
            <span v-else>Name Not Available</span>
          </div>
          <div class="systemId">
            <el-tooltip class="item" effect="dark" content="Your system's customer id." placement="bottom-end">
              <span>
                <i class="fa fa-key" aria-hidden="true"></i>
                <template v-if="'system_id' in customer && customer.system_id">{{ customer.system_id }}</template>
                <template v-else> Not Available </template>
              </span>
            </el-tooltip>
          </div>
        </el-col>

        <!-- Email &  Mobile No -->
        <el-col :span="8">
          <!-- Email if available -->
          <div>
            <div class="fontIconContainer">
              <i class="fa fa-envelope-o" aria-hidden="true"></i>
            </div>
            <span v-if="'email' in customer && customer.email">{{ customer.email }}</span>
            <span v-else>Not Available</span>
          </div>
          <!-- Mobile if available -->
          <div>
            <div class="fontIconContainer">
              <i class="fa fa-phone" aria-hidden="true"></i>
            </div>
            <span v-if="'mobile' in customer && customer.mobile">{{ customer.mobile }}</span>
            <span v-else>Not Available</span>
          </div>
        </el-col>

        <!-- Created & Last Seen -->
        <!-- Created & Last Seen -->
        <el-col :span="4">
          <div class="fontIconContainer">
            <i class="fa fa-eye" aria-hidden="true"></i>
          </div>
          {{ getReleventTime(customer.last_event_at) }}
          <br />
          <div class="fontIconContainer">
            <i class="fa fa-plus-square-o" aria-hidden="true"></i>
          </div>
          Since {{ getCustomerDisplayDate(customer.created_at) }}
        </el-col>

        <!-- Send Template Message -->
        <el-col class="text-3xl text-right" :span="4">
          <el-button @click="sendTemplateMessage(customer)" size="small" type="primary" plain class="px-2">
            Send Template Message &nbsp;
            <i class="fa-solid fa-paper-plane-top fa-rotate-by" style="--fa-rotate-angle: -45deg"></i>
          </el-button>
        </el-col>
      </el-row>

      <!-- No Data -->
      <div class="bg-white text-center border-b" v-if="!fetchingData && customerList.length == 0">
        <div class="py-36 text-gray-500 text-base">No Customers Found.</div>
      </div>

      <!-- Bottom Pagination -->
      <div class="pagination pagination-no-borders">
        <div class="totalCount">Total {{ pageOptions.total }} Customers</div>
        <el-pagination @current-change="onPageChange" :current-page.sync="pageOptions.pageNumber" :page-size="pageOptions.pageSize" layout="prev, pager, next" :total="pageOptions.total"></el-pagination>
      </div>
    </div>

    <!-- Loader -->
    <div class="w-full border rounded-md overflow-hidden" v-if="fetchingData" v-loading="true" style="height: 500px"></div>

    <sendTemplateMessagePopup :templateSelector="true" ref="SendTemplateMessageDialog"></sendTemplateMessagePopup>
  </div>
</template>

<style lang="scss" src="./customerList.scss"></style>

<script>
import CustomerListComponent from './customerListComponent';
export default CustomerListComponent;
</script>
